import * as Yup from 'yup';

const GeneralHealthInfoValidationSchema = Yup.object().shape({
    under_care_of_physician: Yup.string()
        .required('This field is required')
        .oneOf(['yes', 'no'], 'Invalid selection'),

    last_physical_exam: Yup.date()
        .required('Date of last physical exam is required')
        .max(new Date(), 'Date cannot be in the future'),

    being_treated_for_injury_or_illness: Yup.string()
        .required('This field is required')
        .oneOf(['yes', 'no'], 'Invalid selection'),

    // ... add other fields as needed
});

export default GeneralHealthInfoValidationSchema;

