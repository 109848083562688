import * as Yup from 'yup';

const nameRegex = /^[a-zA-Z- ']+$/; // Allows letters, spaces, hyphens, and apostrophes

// Validation Schema for Basic Information
const BasicInfoValidationSchema = Yup.object().shape({
    firstName: Yup.string()
        .matches(nameRegex, 'Invalid characters in first name')
        .max(50, 'First name cannot be longer than 50 characters')
        .required('First name is required'),
    lastName: Yup.string()
        .matches(nameRegex, 'Invalid characters in last name')
        .max(50, 'Last name cannot be longer than 50 characters')
        .required('Last name is required'),
    middleInitial: Yup.string()
        .max(1, 'Only 1 letter is allowed for middle initial')
        .matches(/^[A-Za-z]?$/, 'Middle initial must be a letter'),
    preferredName: Yup.string()
        .matches(nameRegex, 'Invalid characters in preferred name')
        .max(50, 'Preferred name cannot be longer than 50 characters'),
    dob: Yup.date()
        .max(new Date(), 'Date of birth cannot be in the future')
        .required('Date of birth is required'),
    gender: Yup.string().required('Gender is required'),
    ssn: Yup.string()
        .matches(/^\d{3}-\d{2}-\d{4}$/, 'SSN must be in the format XXX-XX-XXXX')
        .required('SSN is required'),
    maritalStatus: Yup.string().required('Marital status is required'),
    referralSource: Yup.string().required('Referral source is required'),
    otherSource: Yup.string()
        .when('referralSource', (referralSource, schema) => {
            return referralSource === 'other' ? schema.required('Please specify the other referral source') : schema;
        }),
});

export default BasicInfoValidationSchema;
