import React from 'react';
import { Field } from 'formik';
import ErrorMessage from './common/ErrorMessage';

const GeneralHealthInfo = ({
  values,
  errors,
  touched,
  previousSection,
  nextSection,
  validateForm,
  setTouched
}) => {
  return (
    <div>
        <h2>General Health Information</h2>

        {/* Question about the care of a physician */}
        <div className="form-group">
          <label className="form-label">Under the care of a Physician?</label>
          <div>
            <label>
              <Field type="radio" name="under_care_of_physician" value="yes" />
              Yes
            </label>
            <label>
              <Field type="radio" name="under_care_of_physician" value="no" />
              No
            </label>
          </div>
          <ErrorMessage name="under_care_of_physician" errors={errors} touched={touched} />
        </div>

        {/* Last Date of Visit */}
        <div className="form-group-physical-date">
          <label htmlFor="last_physical_exam" className="form-label">Last Date of Physical Exam:</label>
          <Field name="last_physical_exam" type="date" />
          <ErrorMessage name="last_physical_exam" errors={errors} touched={touched} />
        </div>

        {/* Question about being treated for injury or illness */}
        <div className="form-group">
          <label className="form-label">Are you presently being treated for injury or illness?</label>
          <div>
            <label>
              <Field type="radio" name="being_treated_for_injury_or_illness" value="yes" />
              Yes
            </label>
            <label>
              <Field type="radio" name="being_treated_for_injury_or_illness" value="no" />
              No
            </label>
          </div>
          <ErrorMessage name="being_treated_for_injury_or_illness" errors={errors} touched={touched} />
        </div>

        <button type="button" onClick={previousSection}>Back</button>
        <button type="button" onClick={() => nextSection(values, { validateForm, setTouched, touched })}>Next</button>
    </div>
  );
};

export default GeneralHealthInfo;
