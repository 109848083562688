import React from 'react';
import { Field, useFormikContext } from 'formik';
import ErrorMessage from './common/ErrorMessage';
import FileInput from './common/FileInput';

const UploadSecondaryInsurance = ({ previousSection, nextSection }) => {
  const { values, errors, touched, validateForm, setTouched, setFieldValue } = useFormikContext();

  const handleNextSection = async () => {
    const formErrors = await validateForm();
    setTouched({ ...touched, ...Object.keys(formErrors).reduce((acc, key) => ({ ...acc, [key]: true }), {}) });

    if (Object.keys(formErrors).length === 0) {
      nextSection(values, { validateForm, setTouched, touched });
    }
  };

  return (
    <div>
      <h2>Secondary Insurance Information</h2>
      <p>Do you have secondary insurance that you would like to upload?</p>
      
      <div>
        <label>
          <Field type="radio" name="hasSecondaryInsurance" value="yes" />
          Yes
        </label>
        <label>
          <Field type="radio" name="hasSecondaryInsurance" value="no" />
          No
        </label>
      </div>
      <ErrorMessage name="hasSecondaryInsurance" errors={errors} touched={touched} />
      
      {values.hasSecondaryInsurance === 'yes' && (
        <>
          <div>
            <label>Relationship:</label>
            <Field as="select" name="insurance_secondary_relationship">
              <option value="">Select Relationship</option>
              <option value="Self">Self</option>
              <option value="Spouse">Spouse</option>
              <option value="Child">Child</option>
              <option value="Other Adult">Other Adult</option>
            </Field>
          </div>
          <ErrorMessage name="insurance_secondary_relationship" errors={errors} touched={touched} />

          <p>Please upload a photo of the front and back of your secondary insurance card.</p>

          <Field
            name="insurance_secondary_front"
            component={FileInput}
            label="Choose File"
            metadata={{ category: 131, imgType: 'Photo', description: 'Secondary Insurance Front', relationship: values.insurance_secondary_relationship }}
          />
          <ErrorMessage name="insurance_secondary_front" errors={errors} touched={touched} />
          
          <Field
            name="insurance_secondary_back"
            component={FileInput}
            label="Choose File"
            metadata={{ category: 131, imgType: 'Photo', description: 'Secondary Insurance Back', relationship: values.insurance_secondary_relationship }}
          />
          <ErrorMessage name="insurance_secondary_back" errors={errors} touched={touched} />
        </>
      )}

      <button type="button" onClick={previousSection}>Back</button>
      <button type="button" onClick={handleNextSection}>Next</button>
    </div>
  );
};

export default UploadSecondaryInsurance;
