// /src/components/common/MiddleInitialInput.js

import React from 'react';

const MiddleInitialInput = ({ value, onChange }) => {

  const handleChange = (event) => {
    let { value } = event.target;
    value = value.toUpperCase(); // Optionally convert to uppercase
    value = value.slice(0, 1); // Limit to the first character
    onChange(value); // Propagate changes up to the parent component/Formik
  };

  return (
    <input
      type="text"
      value={value}
      onChange={handleChange}
      maxLength={1}
      placeholder="Middle Initial"
    />
  );
};

export default MiddleInitialInput;
