import React from 'react';
import '../FormStyles.css';

const ThankYouPage = () => {
  return (
    <div className="container">
      <div className="thank-you-section">
        <h1>Thank You!</h1>
        <p>Thank you for completing the questionnaire. Your responses have been recorded successfully.</p>
        <p>We appreciate your time and effort.</p>
      </div>
    </div>
  );
}

export default ThankYouPage;
