import React from 'react';
import { Field } from 'formik';
import ErrorMessage from './common/ErrorMessage';

const stateCodes = {
  'Alabama': 'AL', 'Alaska': 'AK', 'Arizona': 'AZ', 'Arkansas': 'AR', 'California': 'CA',
  'Colorado': 'CO', 'Connecticut': 'CT', 'Delaware': 'DE', 'Florida': 'FL', 'Georgia': 'GA',
  'Hawaii': 'HI', 'Idaho': 'ID', 'Illinois': 'IL', 'Indiana': 'IN', 'Iowa': 'IA',
  'Kansas': 'KS', 'Kentucky': 'KY', 'Louisiana': 'LA', 'Maine': 'ME', 'Maryland': 'MD',
  'Massachusetts': 'MA', 'Michigan': 'MI', 'Minnesota': 'MN', 'Mississippi': 'MS',
  'Missouri': 'MO', 'Montana': 'MT', 'Nebraska': 'NE', 'Nevada': 'NV', 'New Hampshire': 'NH',
  'New Jersey': 'NJ', 'New Mexico': 'NM', 'New York': 'NY', 'North Carolina': 'NC',
  'North Dakota': 'ND', 'Ohio': 'OH', 'Oklahoma': 'OK', 'Oregon': 'OR', 'Pennsylvania': 'PA',
  'Rhode Island': 'RI', 'South Carolina': 'SC', 'South Dakota': 'SD', 'Tennessee': 'TN',
  'Texas': 'TX', 'Utah': 'UT', 'Vermont': 'VT', 'Virginia': 'VA', 'Washington': 'WA',
  'West Virginia': 'WV', 'Wisconsin': 'WI', 'Wyoming': 'WY'
};

const AddressInformation = ({
  values,
  errors,
  touched,
  previousSection,
  nextSection,
  validateForm,
  setTouched
}) => {
  return (
    <div>
      <h2>Address Information</h2>
      <Field name="address" type="text" placeholder="Address" />
      <ErrorMessage name="address" errors={errors} touched={touched} />

      <Field name="city" type="text" placeholder="City" />
      <ErrorMessage name="city" errors={errors} touched={touched} />

      <Field as="select" name="state">
        <option value="">Select a State</option>
        {Object.entries(stateCodes).map(([state, code]) => (
          <option key={code} value={code}>{state}</option>
        ))}
      </Field>
      <ErrorMessage name="state" errors={errors} touched={touched} />

      <Field name="zip" type="text" placeholder="ZIP" />
      <ErrorMessage name="zip" errors={errors} touched={touched} />

      <button type="button" onClick={previousSection}>Back</button>
      <button type="button" onClick={() => nextSection(values, { validateForm, setTouched, touched })}>Next</button>
    </div>
  );
};

export default AddressInformation;
