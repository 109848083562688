// /src/components/formSections/UploadPhotoID.js
import React from 'react';
import { Field } from 'formik';
import ErrorMessage from './common/ErrorMessage';
import FileInput from './common/FileInput';

const UploadPhotoID = ({
  values,
  errors,
  touched,
  previousSection,
  nextSection,
  validateForm,
  setTouched
}) => {
  // Log the current values, errors, and touched fields
  console.log("UploadPhotoID values:", values);
  console.log("UploadPhotoID errors:", errors);
  console.log("UploadPhotoID touched:", touched);

  return (
    <div>
        <h2>Upload Photo ID</h2>
        <p>Please use your phone to take a photo or upload a file for each of the following:</p>

        {/* Patient Photo */}
        <Field 
            name="patient_photo" 
            component={FileInput} 
            label="Choose Photo" 
            metadata={{ category: 190, imgType: 'Photo', description: 'Patient Photo' }} 
        />
        <ErrorMessage name="patient_photo" errors={errors} touched={touched} />

        {/* ID Front */}
        <Field 
            name="id_front" 
            component={FileInput} 
            label="Choose ID Front" 
            metadata={{ category: 138, imgType: 'Photo', description: 'ID Front' }} 
        />
        <ErrorMessage name="id_front" errors={errors} touched={touched} />

        {/* ID Back */}
        <Field 
            name="id_back" 
            component={FileInput} 
            label="Choose ID Back" 
            metadata={{ category: 138, imgType: 'Photo', description: 'ID Back' }} 
        />
        <ErrorMessage name="id_back" errors={errors} touched={touched} />

        <button type="button" onClick={previousSection}>Back</button>
        <button type="button" onClick={() => {
            console.log("Next button clicked. Current values:", values);
            nextSection(values, { validateForm, setTouched, touched });
        }}>Next</button>
    </div>
  );
};

export default UploadPhotoID;
