import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Formik, Form } from 'formik';
import '../FormStyles.css';
import { useNavigate } from 'react-router-dom';
import logger from '../utils/logger';

//import * as Yup from 'yup';
//import SignatureCanvas from 'react-signature-canvas';

import BasicInformation from '../components/formSections/BasicInfo';
import BasicInfoValidationSchema from '../validationSchemas/BasicInfoValidationSchema';

import AddressInformation from '../components/formSections/AddressInfo';
import AddressInfoValidationSchema from '../validationSchemas/AddressInfoValidationSchema';

import UploadPhotoID from '../components/formSections/UploadPhotoID';
import UploadPhotoIDValidationSchema from '../validationSchemas/UploadPhotoIDValidationSchema';

import UploadPrimaryInsurance from '../components/formSections/UploadPrimaryInsurance';
import PrimaryInsuranceValidationSchema from '../validationSchemas/PrimaryInsuranceValidationSchema';

import UploadSecondaryInsurance from '../components/formSections/UploadSecondaryInsurance';
import SecondaryInsuranceValidationSchema from '../validationSchemas/SecondaryInsuranceValidationSchema';

import GeneralHealthInformation from '../components/formSections/GeneralHealthInfo';
import GeneralHealthInfoValidationSchema from '../validationSchemas/GeneralHealthInfoValidationSchema';

import MedicalConditions from '../components/formSections/MedicalConditions';
import MedicalConditionsValidationSchema from '../validationSchemas/MedicalConditionsValidationSchema';

import SignatureSection from '../components/formSections/SignatureSection';
import SignatureSectionValidationSchema from '../validationSchemas/SignatureSectionValidationSchema';

// Main Intake Form Component
const IntakeForm = () => {
  const [currentSection, setCurrentSection] = useState('basicInfo');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [csrfToken, setCsrfToken] = useState('');

  // Fetch CSRF token when component mounts
  useEffect(() => {
    const fetchCsrfToken = async () => {
      logger.log('Attempting to fetch CSRF token'); // Added log
      const backendApiUrl = process.env.REACT_APP_BACKEND_API_URL;
      try {
        const response = await axios.get(`${backendApiUrl}/api/csrf-token`, { withCredentials: true });
          setCsrfToken(response.data.csrfToken);
          logger.log('CSRF token fetched:', response.data.csrfToken); // Log the fetched token
      } catch (error) {
          logger.error('Error fetching CSRF token:', error);
      }
    };

    fetchCsrfToken();
  }, []);

  // Log CSRF token on state change
  useEffect(() => {
    logger.log('CSRF Token Updated:', csrfToken);
  }, [csrfToken]);

  // Initialize form values with defaults for all fields
  const [formValues, setFormValues] = useState({
      firstName: '',
      lastName: '',
      middleInitial: '',
      preferredName: '',
      dob: '',
      gender: '',
      ssn: '',
      maritalStatus: '',
      referralSource: '',
      otherSource: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      patient_photo: null,
      id_front: null,
      id_back: null,
      hasPrimaryInsurance: '',
      insurance_primary_front: null,
      insurance_primary_front_relationship: '',
      insurance_primary_back: null,
      insurance_primary_back_relationship: '',
      hasSecondaryInsurance: '',
      insurance_secondary_front: null,
      insurance_secondary_front_relationship: '',
      insurance_secondary_back: null,
      insurance_secondary_back_relationship: '',
      under_care_of_physician: '',
      last_physical_exam: '',
      digestive_conditions: '',
      signing_as: '',
      full_name: '',
      signature: null,
      // Add default values for all other fields used in the form
  });

  // State to hold the signature data
  const [signatureData, setSignatureData] = useState(null);

  const navigate = useNavigate();
  
  // Submit Form Component
  const submitForm = async (values) => {
    logger.log("Submitting Form", values);

    // Ensure signature is present
    if (!values.signature || values.signature === '') {
        logger.error("Signature is required");
        return;
    }

    // If signature data is available, set it in the values
    if (signatureData) {
        values.signature = signatureData;
        logger.log("Signature Data:", signatureData);
    }

    logger.log("Final form values including signature:", values);

    const formData = new FormData();

    Object.keys(values).forEach(key => {
        let value = values[key];

        // Convert array values to strings
        if (Array.isArray(value)) {
            value = value[0]; // Use the first element of the array
        }

        // Handle file uploads and associated metadata
        if (['patient_photo', 'id_front', 'id_back', 'insurance_primary_front', 'insurance_primary_back', 'insurance_secondary_front', 'insurance_secondary_back'].includes(key)) {
            if (value && value.file) {
                formData.append(key, value.file);

                // Handle metadata
                if (value.metadata) {
                    Object.keys(value.metadata).forEach(metaKey => {
                        const metaValue = Array.isArray(value.metadata[metaKey]) ? value.metadata[metaKey][0] : value.metadata[metaKey];
                        formData.append(`${key}_${metaKey}`, metaValue);
                    });
                }

                // Append relationship metadata separately, ensuring it's a string
                if (values[`${key}_relationship`]) {
                    const relationshipValue = Array.isArray(values[`${key}_relationship`]) ? values[`${key}_relationship`][0] : values[`${key}_relationship`];
                    formData.append(`${key}_relationship`, relationshipValue);
                }
            }
        } else {
            // For non-file fields, append the value directly
            formData.append(key, value);
        }
    });
    

    // Log CSRF token before sending the request
    logger.log("CSRF Token being sent:", csrfToken);

    try {
        const backendApiUrl = process.env.REACT_APP_BACKEND_API_URL;
        logger.log("Session ID being sent:", document.cookie);
        const response = await axios.post(`${backendApiUrl}/submit-form`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-CSRF-TOKEN': csrfToken,
            },
            withCredentials: true,
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadProgress(percentCompleted);
            }
        });
        logger.log("Response Message:", response.data.message);
        
        if (response.data.message === 'Intake form processed successfully') {
            navigate('/thank-you');
        } else {
          logger.error('Form submission was not successful:', response.data.message);
        }
    } catch (error) {
      logger.error('Error submitting form:', error);

        if (error.response) {
            logger.error('Response error details:', error.response.data);

            // Check for 401 Unauthorized response
            if (error.response.status === 401) {
                navigate('/error', { state: { message: 'Session has expired or is invalid' } });
            }
        }
    }

    // Reset upload progress after submission
    setUploadProgress(0);
};

const nextSection = async (values, { validateForm, setTouched, touched }) => {
  logger.log("Next Section clicked. Current section: ", currentSection);
  logger.log("Current values: ", values);

  // Skip validation for insurance upload if user selects 'No'
  if ((currentSection === 'insurancePrimary' && values.hasPrimaryInsurance === "no") ||
      (currentSection === 'insuranceSecondary' && values.hasSecondaryInsurance === "no")) {
    setFormValues(values); // Update form values
    proceedToNextSection(); // Proceed without validating insurance upload fields
  } else {
    // Proceed with standard validation logic
    const errors = await validateForm(values);
    logger.log("Validation errors: ", errors);
    setTouched({ ...touched, ...Object.keys(values).reduce((acc, key) => ({ ...acc, [key]: true }), {}) });

    if (Object.keys(errors).length === 0) {
      setFormValues(values); // Update form values
      proceedToNextSection(); // Proceed to next section
    } else {
      setTouched({ ...touched, ...errors });
    }
  }
};

// Helper function to determine and set the next section based on the current section
function proceedToNextSection() {
  switch (currentSection) {
    case 'basicInfo':
      setCurrentSection('addressInfo');
      break;
    case 'addressInfo':
      setCurrentSection('photoIDInfo');
      break;
    case 'photoIDInfo':
      setCurrentSection('insurancePrimary');
      break;
    case 'insurancePrimary':
      setCurrentSection('insuranceSecondary');
      break;
    case 'insuranceSecondary':
      setCurrentSection('generalHealthInfo');
      break;
    case 'generalHealthInfo':
      setCurrentSection('medicalConditions');
      break;
    case 'medicalConditions':
      setCurrentSection('signatureInfo');
      break;
    default:
      console.error("Unknown section");
      // Handle the default case or error
  }
}

const previousSection = () => {
  switch (currentSection) {
    case 'addressInfo':
      setCurrentSection('basicInfo');
      break;
    case 'photoIDInfo':
      setCurrentSection('addressInfo');
      break;
    case 'insurancePrimary':
      setCurrentSection('photoIDInfo'); // Navigate back to photoIDInfo from insurancePrimary
      break;
    case 'insuranceSecondary':
      setCurrentSection('insurancePrimary'); // Navigate back to insurancePrimary from insuranceSecondary
      break;
    case 'generalHealthInfo':
      setCurrentSection('insuranceSecondary'); // Navigate back to insuranceSecondary from generalHealthInfo
      break;
    case 'medicalConditions':
      setCurrentSection('generalHealthInfo');
      break;
    case 'signatureInfo':
      setCurrentSection('medicalConditions');
      break;
    default:
      // Handle default case or error
      console.error("Unknown section");
  }
};

  return (
    <>
            {
                uploadProgress > 0 && (
                    <div className="progress-bar-container">
                        <div className="upload-message">
                            {uploadProgress < 100 ? "Uploading Files..." : "Saving Files... Do Not Close"}
                        </div>
                        <div className="progress-bar" style={{ width: `${uploadProgress}%` }}>
                            {uploadProgress}%
                        </div>
                    </div>
                )
            }

        <Formik
        initialValues={formValues}
        validationSchema={
            currentSection === 'basicInfo' ? BasicInfoValidationSchema :
            currentSection === 'addressInfo' ? AddressInfoValidationSchema :
            currentSection === 'photoIDInfo' ? UploadPhotoIDValidationSchema : //
            currentSection === 'insurancePrimary' ? PrimaryInsuranceValidationSchema :
            currentSection === 'insuranceSecondary' ? SecondaryInsuranceValidationSchema :
            currentSection === 'generalHealthInfo' ? GeneralHealthInfoValidationSchema : // Define this schema
            currentSection === 'medicalConditions' ? MedicalConditionsValidationSchema : // Define this schema
            currentSection === 'signatureInfo' ? SignatureSectionValidationSchema : // Define this schema
            null
        }
        onSubmit={submitForm}
    >
   {({ values, errors, handleSubmit, validateForm, setTouched, touched, setFieldValue }) => (
    <Form onSubmit={handleSubmit}>
    {currentSection === 'basicInfo' &&
      <BasicInformation 
      values={values} 
      errors={errors}
      touched={touched}
      validateForm={validateForm}
      setTouched={setTouched}
      setFieldValue={setFieldValue}
      nextSection={(values, formikHelpers) => nextSection(values, formikHelpers)}
    />
    }
    {currentSection === 'addressInfo' &&
      <AddressInformation 
        values={values}
        errors={errors}
        touched={touched}
        validateForm={validateForm}
        setTouched={setTouched}
        previousSection={previousSection} 
        nextSection={() => nextSection(values, { validateForm, setTouched, touched })}
      />
    }
    {currentSection === 'photoIDInfo' &&
      <UploadPhotoID
        errors={errors}
        touched={touched}
        validateForm={validateForm}
        setTouched={setTouched}
        previousSection={previousSection} 
        nextSection={() => nextSection(values, { validateForm, setTouched, touched })}
      />
    }
    {currentSection === 'insurancePrimary' && (
                <UploadPrimaryInsurance
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    previousSection={previousSection} 
                    nextSection={() => nextSection(values, { validateForm, setTouched, touched })}
                />
            )}
            {currentSection === 'insuranceSecondary' && (
                <UploadSecondaryInsurance
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    previousSection={previousSection} 
                    nextSection={() => nextSection(values, { validateForm, setTouched, touched })}
                />
            )}
    {currentSection === 'generalHealthInfo' &&
      <GeneralHealthInformation
        errors={errors}
        touched={touched}
        validateForm={validateForm}
        setTouched={setTouched}
        previousSection={previousSection} 
        nextSection={() => nextSection(values, { validateForm, setTouched, touched })}
      />
    }
    {currentSection === 'medicalConditions' &&
      <MedicalConditions
        errors={errors}
        touched={touched}
        validateForm={validateForm}
        setTouched={setTouched}
        previousSection={previousSection} 
        nextSection={() => nextSection(values, { validateForm, setTouched, touched })}
      />
    }
    {currentSection === 'signatureInfo' &&
          <SignatureSection 
            errors={errors}
            touched={touched}
            setSignatureData={setSignatureData}
            previousSection={previousSection}
            handleSubmit={handleSubmit}
            directSubmit={() => submitForm(values)}
            setFieldValue={setFieldValue} // Pass setFieldValue to SignatureSection
          />
    }
    {/* Add additional conditions for other sections if needed */}
        </Form>
        )}
    </Formik>
    </>
  );
};
  
export default IntakeForm;
