import React from 'react';
import { Field } from 'formik';
import ErrorMessage from './common/ErrorMessage';

const MedicalConditions = ({
  values,
  errors,
  touched,
  previousSection,
  nextSection,
  validateForm,
  setTouched
}) => (
  <div>
    <h2>Medical Conditions</h2>

    {/* Digestive Conditions */}
    <div className="form-group">
      <label className="form-label">Do you have a history of or are currently being treated for any digestive conditions?</label>
      <div className="small-text">Gastroesophageal Reflux Disease, Irritable Bowel Syndrome, Stomach/Peptic Ulcers, Gallstones, Lactose Intolerance, Diverticulitis, Inflammatory Bowel Disease, Celiac Disease, Constipation, Other…</div>
      <label>
        <Field type="radio" name="digestive_conditions" value="yes" />
        Yes
      </label>
      <label>
        <Field type="radio" name="digestive_conditions" value="no" />
        No
      </label>
      <ErrorMessage name="digestive_conditions" errors={errors} touched={touched} />
    </div>

    {/* Heart or Circulatory Conditions */}
    <div className="form-group">
      <label className="form-label">Do you have a history of or are currently being treated for any Heart or Circulatory conditions?</label>
      <div className="small-text">Coronary Artery Disease (CAD), Heart Arrhythmias, Heart Failure, Heart Valve Disease, Pericardial Disease, Cardiomyopathy (Heart Muscle Disease), Congenital Heart Disease, Peripheral Artery Disease, Stroke, High Blood Pressure, Low Blood Pressure, Heart Attack, Artificial Heart Valve, Other…</div>
      <label>
        <Field type="radio" name="heart_conditions" value="yes" />
        Yes
      </label>
      <label>
        <Field type="radio" name="heart_conditions" value="no" />
        No
      </label>
      <ErrorMessage name="heart_conditions" errors={errors} touched={touched} />
    </div>

    {/* Neurological Diseases */}
    <div className="form-group">
      <label className="form-label">Do you have a history of or are currently being treated for any neurological disease?</label>
      <div className="small-text">ALS, Arteriovenous Malformation, Brain Aneurysm, Brain Tumors, Epilepsy, Seizures, Stroke, Migraines/Severe Headaches, Memory Disorders, Parkinson’s Disease, Alzheimer’s, Dementia, Other…</div>
      <label>
        <Field type="radio" name="neurological_diseases" value="yes" />
        Yes
      </label>
      <label>
        <Field type="radio" name="neurological_diseases" value="no" />
        No
      </label>
      <ErrorMessage name="neurological_diseases" errors={errors} touched={touched} />
    </div>

    <button type="button" onClick={previousSection}>Back</button>
    <button type="button" onClick={() => nextSection(values, { validateForm, setTouched, touched })}>Next</button>
  </div>
);

export default MedicalConditions;
