// /src/utils/logger.js
const isDebugMode = process.env.REACT_APP_DEBUG_MODE === 'true';

const logger = {
  log: (...args) => {
    if (isDebugMode) {
      console.log(...args);
    }
  },
  error: (...args) => {
    if (isDebugMode) {
      console.error(...args);
    }
  }
};

export default logger;
