import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import logger from '../utils/logger';

const CheckAccess = () => {
    const [error, setError] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const [csrfToken, setCsrfToken] = useState(''); // Add state for CSRF token

    // Function to fetch CSRF token
    const fetchCsrfToken = useCallback(async () => {
        try {
            const backendApiUrl = process.env.REACT_APP_BACKEND_API_URL;
            const response = await axios.get(`${backendApiUrl}/api/csrf-token`, { withCredentials: true });
            setCsrfToken(response.data.csrfToken); // Assuming your backend sends the token in a `csrfToken` field
            logger.log('CSRF token fetched:', response.data.csrfToken);
        } catch (error) {
            logger.error('Error fetching CSRF token:', error);
            setError('Could not fetch CSRF token');
        }
    }, [setError]);

    // Validate token function
    const validateToken = useCallback(async (token) => {
        try {
            const backendApiUrl = process.env.REACT_APP_BACKEND_API_URL;
            // You might need to adjust this request to include the CSRF token if required by your backend
            const response = await axios.get(`${backendApiUrl}/check-access?token=${token}`, {
                withCredentials: true,
                headers: {
                    'X-CSRF-TOKEN': csrfToken, // Include CSRF token in the request headers if necessary
                }
            });
            logger.log(response.data);
            if (response.data.success) {
                navigate(response.data.redirectUrl);
            }
        } catch (err) {
            setError(err.response?.data || 'An error occurred during token validation');
        }
    }, [csrfToken, navigate]); // Add csrfToken to the dependencies array

    useEffect(() => {
        fetchCsrfToken();
    }, [fetchCsrfToken]); // Fetch CSRF token when component mounts

    useEffect(() => {
        logger.log("useEffect triggered for token validation");
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');

        if (token) {
            validateToken(token);
        } else {
            setError('No token provided');
        }
    }, [location, validateToken]); // Add validateToken to the dependency array

    return (
        <div>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {/* You can add more UI elements here if needed */}
        </div>
    );
};

export default CheckAccess;
