// /src/components/common/SSNInput.js
import React, { useState, useEffect } from 'react';

const SSNInput = ({ value, onChange }) => {
  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (isFocused || !value) {
      setInputValue(formatSSN(value)); // Format value when focused or empty
    } else {
      setInputValue(maskSSN(value)); // Masked value when not focused
    }
  }, [isFocused, value]);

  const formatSSN = (ssn) => {
    ssn = ssn.replace(/\D/g, ''); // Remove non-digit characters
    ssn = ssn.substring(0, 9); // Limit to 9 characters

    // Formatting: XXX-XX-XXXX
    if (ssn.length > 5) {
      return ssn.substring(0, 3) + '-' + ssn.substring(3, 5) + '-' + ssn.substring(5, 9);
    } else if (ssn.length > 3) {
      return ssn.substring(0, 3) + '-' + ssn.substring(3, 5);
    } else {
      return ssn;
    }
  };

  const maskSSN = (ssn) => {
    // Replace each digit with 'X'
    return ssn.replace(/\d/g, 'X');
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleChange = (event) => {
    let { value } = event.target;
    value = formatSSN(value); // Format the input value
    setInputValue(value); // Update the inputValue state
    onChange(value); // Propagate changes up to the parent component/Formik
  };

  return (
    <input
      type="text"
      value={inputValue}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      placeholder="SSN (XXX-XX-XXXX)"
    />
  );
};

export default SSNInput;
