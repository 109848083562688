import * as Yup from 'yup';

const fileValidation = Yup.mixed()
  .required('A file is required')
  .test('fileSize', 'The file is too large', value => {
    return value && value.file && value.file.size <= 100 * 1024 * 1024; // 100MB size limit
  })
  .test('fileType', 'Unsupported file format', value => {
    const allowedFileTypes = [
      'image/jpeg',
      'image/png',
      'image/heic',
      'image/heif',
      'image/webp',
      'image/tiff',
      'image/gif', // Include if you want to allow GIFs
      'image/x-adobe-dng' // DNG format for RAW images
      // Add any other specific file types you want to allow
    ];
    return value && value.file && allowedFileTypes.includes(value.file.type);
  });

// Validation schema for photo ID section
const PhotoIDValidationSchema = Yup.object().shape({
  patient_photo: fileValidation,
  id_front: fileValidation,
  id_back: fileValidation,
});

export default PhotoIDValidationSchema;
