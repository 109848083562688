import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../FormStyles.css';

const TermsPage = () => {
    const navigate = useNavigate();
    const [dob, setDob] = useState('');
    const [error, setError] = useState('');
    const [acceptTerms, setAcceptTerms] = useState(false); // State to track terms acceptance

    // Function to handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        const csrfToken = document.cookie.split('; ')
            .find(row => row.startsWith('XSRF-TOKEN='))
            ?.split('=')[1];
    
        try {
            const backendApiUrl = process.env.REACT_APP_BACKEND_API_URL;
            const response = await axios.post(`${backendApiUrl}/terms`, {
                dob, 
                acceptTerms
            }, { 
                withCredentials: true,
                headers: {
                    'X-CSRF-TOKEN': csrfToken, // Adjust header name as per your backend configuration
                }
            });

            if (response.data.redirectUrl) {
                navigate(response.data.redirectUrl);
            } else {
                setError('Failed to verify details. Please try again.');
            }
        } catch (err) {
            setError(err.response?.data.error || 'An error occurred during verification');
        }
    };

    return (
        <div>
            <h1>New Patient Form</h1>
            <h2>Terms & Conditions</h2>

            {/* Display Terms & Conditions */}
            <div id="terms-content">
                <div className="terms-text">
                    {/* Insert your Terms & Conditions content here */}
                    <p>Your Terms and Conditions text...</p>
                    {/* ... */}
                </div>
            </div>

            <form onSubmit={handleSubmit}>
                {/* DOB Input */}
                <div>
                    <label htmlFor="dob">Date of Birth:</label>
                    <input
                        type="date"
                        id="dob"
                        name="dob"
                        value={dob}
                        onChange={e => setDob(e.target.value)}
                        required
                    />
                </div>

                {/* Acceptance Checkbox */}
                <div>
                    <input 
                        type="checkbox" 
                        id="accept_terms" 
                        name="accept_terms" 
                        checked={acceptTerms}
                        onChange={e => setAcceptTerms(e.target.checked)} 
                        required 
                    />
                    <label htmlFor="accept_terms">I accept the Terms & Conditions.</label>
                </div>

                {/* Error Message */}
                {error && <p style={{ color: 'red' }}>{error}</p>}

                {/* Proceed Button */}
                <div style={{ marginTop: '20px' }}>
                    <button type="submit">Proceed</button>
                </div>
            </form>
        </div>
    );
};

export default TermsPage;
