import React from 'react';

const ErrorPage = ({ message }) => {
    return (
        <div>
            <h1>Error</h1>
            <p>{message}</p>
            {/* You can add a button or link to redirect users back to a safe page, like the homepage */}
        </div>
    );
};

export default ErrorPage;
