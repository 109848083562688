import React from 'react';
import { Field } from 'formik';
import ErrorMessage from './common/ErrorMessage'; // Single import for ErrorMessage
import MiddleInitialInput from './common/MiddleInitialInput';
import SSNInput from './common/SSNInput';
import logger from '../../utils/logger'; // Adjust the path as necessary

// Component for Basic Information Section
const BasicInformation = ({ values, errors, touched, nextSection, validateForm, setFieldValue, setTouched }) => {
  logger.log("Errors:", errors, "Touched:", touched);
  const ssnValue = values && values.ssn ? values.ssn : '';
  return (
    <div>
      <h2>Basic Information</h2>
      <Field name="firstName" type="text" placeholder="First Name" />
      <ErrorMessage name="firstName" errors={errors} touched={touched} />

      <Field name="lastName" type="text" placeholder="Last Name" />
      <ErrorMessage name="lastName" errors={errors} touched={touched} />

      {/* Middle Initial Field */}
      <div className="form-group-mi">
          <MiddleInitialInput
            value={values.middleInitial}
            onChange={(value) => setFieldValue('middleInitial', value)}
          />
      </div>

      <Field name="preferredName" type="text" placeholder="Preferred Name" />
      <ErrorMessage name="preferredName" errors={errors} touched={touched} />

      {/* Date of Birth Field */}
      <div className="form-group-dob">
        <label htmlFor="dob" className="form-label">Date of Birth:</label>
        <Field name="dob" type="date" />
        <ErrorMessage name="dob" errors={errors} touched={touched} />
      </div>

      {/* Gender Field */}
      <div className="form-group-gender">
        <label className="form-label">Gender:</label>
        <div id="gender" className="gender-radio-group">
          <label>
            <Field type="radio" name="gender" value="Male" />
            Male
          </label>
          <label>
            <Field type="radio" name="gender" value="Female" />
            Female
          </label>
          <label>
            <Field type="radio" name="gender" value="Unknown" />
            Other
          </label>
          <label>
            <Field type="radio" name="gender" value="Unknown" />
            Prefer not to self-identify
          </label>
        </div>
        <ErrorMessage name="gender" errors={errors} touched={touched} />
      </div>

       {/* SSN Field */}
       <div className="form-group-ssn">
          <SSNInput
            value={ssnValue}
            onChange={(value) => setFieldValue('ssn', value)}
          />
          <ErrorMessage name="ssn" errors={errors} touched={touched} />
        </div>

      {/* Marital Status Field */}
      <Field as="select" name="maritalStatus">
        <option value="">Select Marital Status</option>
        <option value="Single">Single</option>
        <option value="Married">Married</option>
        <option value="Widowed">Widowed</option>
        <option value="Divorced">Divorced</option>
      </Field>
      <ErrorMessage name="maritalStatus" errors={errors} touched={touched} />

      {/* Referral Source Field */}
      <Field as="select" name="referralSource">
          <option value="">Select Referral Source</option>
          <option value="google_search">Google Search</option>
          <option value="dentist_referral">Referral from Another Dentist</option>
          <option value="doctor_referral">Referral from Doctor/Healthcare Professional</option>
          <option value="patient_referral">Referral from Existing Patient</option>
          <option value="social_media">Social Media (Facebook, Instagram, etc.)</option>
          <option value="advertisement">Advertisement (Online, TV, Radio, Print)</option>
          <option value="community_event">Community Event or Health Fair</option>
          <option value="insurance_company">Insurance Company</option>
          <option value="website">Clinic Website</option>
          <option value="word_of_mouth">Word of Mouth</option>
          <option value="other">Other</option>
      </Field>

      <ErrorMessage name="referralSource" errors={errors} touched={touched} />

      {values.referralSource === 'other' && (
        <div>
          <Field name="otherSource" type="text" placeholder="If other, please specify" />
          <ErrorMessage name="otherSource" errors={errors} touched={touched} />
        </div>
      )}

      <button type="button" onClick={() => nextSection(values, { validateForm, setTouched, touched })}>Next</button>
    </div>
  );
};

export default BasicInformation;
