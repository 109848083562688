import * as Yup from 'yup';

const addressRegex = /^[a-zA-Z0-9\s,'-]*$/; // Allows letters, numbers, spaces, commas, hyphens, and apostrophes
const cityRegex = /^[a-zA-Z\s-]*$/; // Allows letters, spaces, and hyphens

// Validation Schema for Address Information
const AddressInfoValidationSchema = Yup.object().shape({
    address: Yup.string()
        .matches(addressRegex, 'Invalid characters in address')
        .max(150, 'Address cannot be longer than 150 characters')
        .required('Address is required'),
    city: Yup.string()
        .matches(cityRegex, 'Invalid characters in city')
        .max(30, 'City cannot be longer than 30 characters')
        .required('City is required'),
    state: Yup.string()
        .matches(/^[A-Z]{2}$/, 'State should be a two-letter state code')
        .required('State is required'),
    zip: Yup.string()
        .matches(/^\d{5}(-\d{4})?$/, 'ZIP must be in the format XXXXX or XXXXX-XXXX')
        .required('ZIP is required'),
});

export default AddressInfoValidationSchema;

