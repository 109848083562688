import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CheckAccess from './pages/CheckAccess'; 
import TermsPage from './pages/TermsPage';
import IntakeFormPage from './pages/IntakeFormPage';
import ErrorPage from './pages/ErrorPage'; // Import ErrorPage component
import ThankYouPage from './pages/ThankYouPage'; // Import ThankYouPage component

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/check-access" element={<CheckAccess />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/intake-form" element={<IntakeFormPage />} />
        <Route path="/error" element={<ErrorPage />} /> {/* Route for the error page */}
        <Route path="/thank-you" element={<ThankYouPage />} /> {/* Route for the thank you page */}
        {/* Add other routes as needed */}
      </Routes>
    </Router>
  );
}

export default App;