import React, { useRef } from 'react';
import { Field } from 'formik';
import ErrorMessage from './common/ErrorMessage';
import SignatureCanvas from 'react-signature-canvas';

const SignatureSection = ({ errors, touched, previousSection, setFieldValue, directSubmit }) => {
    const sigPad = useRef(null);

    const handleSignatureEnd = () => {
        if (sigPad.current) {
            const signatureImage = sigPad.current.getTrimmedCanvas().toDataURL('image/png');
            console.log("Signature Captured: ", signatureImage); // Log the signature data
            setFieldValue('signature', signatureImage);
        }
    };

    const clear = () => {
        if (sigPad.current) {
            sigPad.current.clear();
            setFieldValue('signature', ''); // Set signature field value to empty
        }
    };
    

    return (
        <div>
            <h2>Signature</h2>
            
            <div className="form-group">
                <label className="form-label">Signing As:</label>
                <label>
                    <Field type="radio" name="signing_as" value="patient" />
                    Patient
                </label>
                <label>
                    <Field type="radio" name="signing_as" value="parent_guardian" />
                    Parent / Guardian
                </label>
                <ErrorMessage name="signing_as" errors={errors} touched={touched} />
            </div>

            <Field name="full_name" type="text" placeholder="Full Name" />
            <ErrorMessage name="full_name" errors={errors} touched={touched} />

            <div className="signature-container">
            <SignatureCanvas 
                penColor='black'
                canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                ref={sigPad}
                onEnd={handleSignatureEnd}
            />
            </div>

            {errors.signature && touched.signature && <div className="error">{errors.signature}</div>}

            <button type="button" onClick={clear}>Clear</button>
            <button type="button" onClick={previousSection}>Back</button>
            <button type="button" onClick={directSubmit}>Submit</button>
        </div>
    );
};

export default SignatureSection;
