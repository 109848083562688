// /src/components/formSections/common/FileInput.js
import React, { useState } from 'react';

const FileInput = ({ field, form: { setFieldValue }, label, metadata }) => {
    const [fileName, setFileName] = useState('No file chosen');

    const allowedFileTypes = [
        'image/jpeg', 
        'image/png', 
        'image/heic', 
        'image/heif', 
        'image/webp', 
        'image/tiff', 
        'image/gif', // Include if you want to allow GIFs
        'image/x-adobe-dng' // DNG format for RAW images
        // Add any other specific RAW formats your application might need to support
    ];
    // Define allowed file types
    const maxFileSize = 20 * 1024 * 1024; // Max file size (20MB in this case)

    const handleFileChange = (event) => {
        const file = event.currentTarget.files[0];
        if (file) {
            // Check if the file type is allowed
            if (!allowedFileTypes.includes(file.type)) {
                alert("Invalid file type. Please select an image.");
                return;
            }

            // Check file size
            if (file.size > maxFileSize) {
                alert("File is too large. Please select a file smaller than 20MB.");
                return;
            }

            // Include the file and its metadata
            const fileWithMetadata = {
                file,
                metadata,
            };
            console.log("Data being set to field: ", fileWithMetadata);
            setFieldValue(field.name, fileWithMetadata);
            setFileName(file.name);
        } else {
            setFileName('No file chosen');
        }
    };

    return (
        <div className="file-upload">
            <button
                className="upload-btn"
                type="button"
                onClick={() => document.getElementById(field.name).click()}
            >
                {label}
            </button>
            <input
                type="file"
                accept="image/*"
                capture="camera"
                id={field.name}
                name={field.name}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
            <span id={field.name + 'Name'}>{fileName}</span>
        </div>
    );
};

export default FileInput;
