import * as Yup from 'yup';

const SignatureSectionValidationSchema = Yup.object().shape({
    signing_as: Yup.string()
        .oneOf(['patient', 'parent_guardian'], 'Invalid option for signing as')
        .required('Please indicate who is signing'),

    full_name: Yup.string()
        .matches(/^[a-zA-Z- ']+$/, 'Full name should only contain letters, spaces, hyphens, and apostrophes')
        .max(100, 'Full name cannot be longer than 100 characters')
        .required('Full name is required'),

    signature: Yup.string()
        .required('Signature is required')
        .test('is-image', 'Signature must be a valid image', value => {
            // Check if the value is a valid base64 image string
            return /^data:image\/(png|jpeg);base64,/.test(value);
        }),
});

export default SignatureSectionValidationSchema;
