import * as Yup from 'yup';

const MedicalConditionsValidationSchema = Yup.object().shape({
    digestive_conditions: Yup.string().required('Please select an option for digestive conditions'),
    heart_conditions: Yup.string().required('Please select an option for heart or circulatory conditions'),
    neurological_diseases: Yup.string().required('Please select an option for neurological diseases'),
    // ... add validations for other fields as needed
});

export default MedicalConditionsValidationSchema;

